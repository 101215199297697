import { http } from '@/plugins/ky'
import { ADMIN } from '@/constants'
import { join } from 'path'
import { ApiAggrResult } from '@/types/api'

const get = (year?: number, month?: number) => {
  const url = join(ADMIN, 'aggregate', 'purchase')

  if (year === undefined || month === undefined) {
    return http.get(url).json<ApiAggrResult[]>()
  }

  return http
    .get(url, {
      searchParams: {
        year,
        month,
      },
    })
    .json<ApiAggrResult[]>()
}

export { get }
