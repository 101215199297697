<template>
  <div class="lg:w-3/4 grid grid-cols-1 md:grid-cols-4 gap-4 mb-4">
    <div class="flex items-center text-gray-800 font-medium">
      <p class="mr-2">
        {{ t('Sales total per month') }}
      </p>
      <p>
        {{ n(totalPrice, 'currency') }}
      </p>
    </div>
    <div class="flex items-center text-gray-800 font-medium">
      <p class="mr-2">
        {{ t('Purchase total per month') }}
      </p>
      <p>
        {{ t('Under development') }}
      </p>
    </div>
    <div class="flex items-center text-gray-800 font-medium">
      <p class="mr-2">
        {{ t('The commission for Stripe') }}
      </p>
      <p>
        {{ t('Under development') }}
      </p>
    </div>

    <div class="flex items-center text-gray-800 font-medium">
      <p class="mr-2">
        {{ t('Payout to our bank') }}
      </p>
      <p>
        {{ t('Under development') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { get } from '@/api/admin/aggregate/sales'
import { ApiAggregateResult } from '@/types/api'
import { useTranslation } from '@/locales'

type Props = {
  totalPrice: number
  purchaseTotalPrice: number
}

export default defineComponent({
  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
    purchaseTotalPrice: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t, n } = useTranslation()

    return { t, n }
  },
})
</script>
