
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import Thead from '@/components/Parts/Table/Thead.vue'

export default defineComponent({
  name: 'AggregateTab',
  components: { Thead },
  setup() {
    const { t } = useI18n()

    const tags = [
      'Customer',
      'Service Name',
      'Service Detail',
      'Contract Terms (UTC)',
      'Price for this contract',
      'Deposit date',
      'Purchase Terms (JST)',
      'Purchase Price',
      'Subtotal for Purchase',
      'Total for Purchase',
      'Accounts Payable',
      'Subtotal for Accounts Payable',
      'Total for Accounts Payable',
      'Advance Payment',
      'Subtotal for Advance Payment',
      'Total for Advance Payment',
    ]

    return { t, tags }
  },
})
