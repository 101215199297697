
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { get } from '@/api/admin/aggregate/sales'
import { ApiAggregateResult } from '@/types/api'
import { useTranslation } from '@/locales'

type Props = {
  totalPrice: number
  purchaseTotalPrice: number
}

export default defineComponent({
  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
    purchaseTotalPrice: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t, n } = useTranslation()

    return { t, n }
  },
})
