<template>
  <div class="md:w-96">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 z-50">
      <month-picker-input
        lang="ja"
        :default-year="year"
        :default-month="month"
        :show-year="true"
        :input-pre-filled="true"
        @change="change"
      />
      <div class="flex items-center md:ml-2">
        <label class="mr-4">
          <input v-model="select" type="radio" value="Customer" />
          {{ t('Customer') }}
        </label>
        <label>
          <input v-model="select" type="radio" value="Service" />
          {{ t('Service') }}
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { MonthPickerInput } from 'vue-month-picker'
import { useTranslation } from '@/locales'

type Props = {
  year: number
  month: number
}

type Term = {
  year: number
  monthIndex: number
}

export default defineComponent({
  components: { MonthPickerInput },
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },
  emits: ['select', 'changeTerm'],
  setup(props: Props, { emit }) {
    const select = ref('Customer')
    const year = ref(props.year)
    const month = ref(props.month)
    const { t } = useTranslation()

    const change = (data: Term) => {
      if (year.value !== data.year || month.value !== data.monthIndex) {
        year.value = data.year
        month.value = data.monthIndex
        emit('changeTerm', data.year, data.monthIndex)
      }
    }

    watch(select, () => {
      emit('select', select.value)
    })

    return { select, t, change }
  },
})
</script>
