
import { defineComponent, ref, watch } from 'vue'
import { MonthPickerInput } from 'vue-month-picker'
import { useTranslation } from '@/locales'

type Props = {
  year: number
  month: number
}

type Term = {
  year: number
  monthIndex: number
}

export default defineComponent({
  components: { MonthPickerInput },
  props: {
    year: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: true,
    },
  },
  emits: ['select', 'changeTerm'],
  setup(props: Props, { emit }) {
    const select = ref('Customer')
    const year = ref(props.year)
    const month = ref(props.month)
    const { t } = useTranslation()

    const change = (data: Term) => {
      if (year.value !== data.year || month.value !== data.monthIndex) {
        year.value = data.year
        month.value = data.monthIndex
        emit('changeTerm', data.year, data.monthIndex)
      }
    }

    watch(select, () => {
      emit('select', select.value)
    })

    return { select, t, change }
  },
})
