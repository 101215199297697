<template>
  <div>
    <p class="my-4 text-xl font-medium text-gray-700">
      {{ t('Purchase aggregate') }}
    </p>
  </div>
  <selector
    :year="year"
    :month="month"
    @select="select"
    @changeTerm="changeTerm"
  />
  <total-price
    :total-price="totalPrice"
    :purchase-total-price="purchasePrice"
  />
  <customer
    v-if="show === 'Customer' && aggregates.length > 0"
    :aggregate="aggregates"
  />
  <service
    v-if="show === 'Service' && aggregates.length > 0"
    :aggregate="aggregates"
  />
  <p v-if="isProgress" class="mt-4 text-lg font-medium">
    {{ t('Now loading..') }}
  </p>
  <p
    v-if="!isProgress && aggregates.length == 0"
    class="mt-4 text-lg font-medium"
  >
    {{ t('Not found') }}
  </p>
</template>

<script lang="ts">
import { defineComponent, ref, onBeforeMount } from 'vue'
import { get } from '@/api/admin/aggregate/purchase'
import { ApiAggrResult } from '@/types/api'
import Customer from '@/components/Aggregate/Purchase/Customer/index.vue'
import Service from '@/components/Aggregate/Purchase/Service/index.vue'
import Selector from '@/components/Aggregate/Purchase/Selector.vue'
import TotalPrice from '@/components/Aggregate/Purchase/TotalPrice.vue'
import { useArrayRefs, useProgress } from '@/reactives'
import { useTranslation } from '@/locales'

export default defineComponent({
  components: { Customer, Service, Selector, TotalPrice },
  setup() {
    const aggregates = useArrayRefs<ApiAggrResult>()
    const show = ref('Customer')
    const year = ref(2021)
    const month = ref(9)
    const totalPrice = ref(0)
    const purchasePrice = ref(0)
    const { start, done, isProgress } = useProgress(true)
    const { t } = useTranslation()

    const select = (value: string) => {
      show.value = value
    }

    const _getTotal = (prices: ApiAggrResult[]) =>
      prices.reduce(function (accum, val) {
        return accum + 0
      }, 0)

    const _getPurchaseTotal = (prices: ApiAggrResult[]) =>
      prices.reduce(function (accum, val) {
        return accum + 0
      }, 0)

    const changeTerm = (year: number, month: number) => {
      start()
      totalPrice.value = 0
      aggregates.value = []
      get(year, month)
        .then((res) => {
          totalPrice.value = _getTotal(res)
          aggregates.value = [...res]
          done()
        })
        .catch((err) => {
          done()
        })
    }

    onBeforeMount(() => {
      start()
      get()
        .then((res) => {
          totalPrice.value = _getTotal(res)
          purchasePrice.value = _getPurchaseTotal(res)

          if (res.length > 0) {
            year.value = res[0].year
            month.value = res[0].month
          }

          aggregates.value = [...res]
          done()
        })
        .catch((err) => {
          console.log(err)
          done()
        })
    })

    return {
      select,
      show,
      changeTerm,
      aggregates,
      totalPrice,
      purchasePrice,
      year,
      month,
      isProgress,
      t,
    }
  },
})
</script>
